import React from "react";
import { AnimationControls, motion } from "framer-motion";
import { Title, Subtitle } from "../../styles";
import { EntryAnimationWrapper } from "../EntryAnimationWrapper";

interface Props {
  isEntryAnimationDisabled?: boolean;
  animationControls: AnimationControls;
  children: React.ReactNode;
}

export function AccountTypesWrapper({
  isEntryAnimationDisabled = false,
  animationControls,
  children,
}: Props) {
  return (
    <motion.div animate={animationControls}>
      {/* If we navigated to this route directly, disable entry animation */}
      <EntryAnimationWrapper disabled={isEntryAnimationDisabled}>
        <Title>Select Your Dancer</Title>
        <Subtitle>Who will be dancing on STEEZY?</Subtitle>
        {children}
      </EntryAnimationWrapper>
    </motion.div>
  );
}
